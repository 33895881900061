import { AnyAction } from 'redux';
import cashieringTypes from 'store/cashiering/types';
import reservationTypes from 'store/reservation/types';
import { Api } from 'utils';
import checkInIDScanningStore from 'views/CheckInIDScanning/store/types';

interface LoggingActionBlackList {
  [key: string]: boolean | ((action: AnyAction) => boolean);
}

const isBlacklistedPaymentError = (action: AnyAction): boolean => {
  if (action?.response) return false;
  const errorMessagesToIgnore = ['DECLINED'];
  const shouldErrorBeBlockerFromLogs = Api.getErrors(action.response)?.some(
    (error) =>
      error?.message
        ? errorMessagesToIgnore.some((messageToIgnore) =>
            error.message.includes(messageToIgnore)
          )
        : false
  );

  return shouldErrorBeBlockerFromLogs ?? false;
};

const loggingBlacklistOfAction: LoggingActionBlackList = {
  [reservationTypes.FETCH_RESERVATION_CHECK_IN_FAILURE]: true,
  [reservationTypes.UPDATE_RESERVATION_PURCHASES_FAILURE_UNWATCHED]: true,
  [reservationTypes.FETCH_RESERVATION_CHECK_OUT_FAILURE]: true,
  [reservationTypes.FETCH_RESERVATION_GENERATE_KEYS_FAILURE]: true,
  [checkInIDScanningStore.DOCUMENT_SCAN_FAILURE]: true,
  [cashieringTypes.FETCH_RESERVATION_OUTSTANDING_DEPOSIT.FAILURE]: true,
  [cashieringTypes.ADD_TRANSACTION_FAILURE]: true,
  [cashieringTypes.FETCH_PAYMENT_METHOD_STATUS_FAILURE]:
    isBlacklistedPaymentError,
  [cashieringTypes.FETCH_ADD_TRANSACTION_STATUS_FAILURE]:
    isBlacklistedPaymentError,
};

export const isActionOnLoggingBlacklist = (
  action: AnyAction
): boolean | undefined => {
  const actionType = action.type;
  const backlistValue = loggingBlacklistOfAction[actionType];

  return typeof backlistValue === 'function'
    ? backlistValue(action)
    : backlistValue;
};
