import { fork } from 'redux-saga/effects';

import { backgroundTasksSagas } from '@gss/store/backgroundTasks/sagas';
import { configurationSagas } from '@gss/store/configuration/sagas';
import { lazyLoadedDictionarySagas } from '@gss/store/lazyLoadedDictionary/sagas';
import { mainProcessSagas } from '@gss/store/mainProcess/sagas';
import { settingsSagas } from '@gss/store/settings/sagas';

export const globalSagas = [
  fork(configurationSagas),
  fork(settingsSagas),
  fork(backgroundTasksSagas),
  fork(mainProcessSagas),
  fork(lazyLoadedDictionarySagas),
];
