import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ids } from 'config';
import { compose } from 'redux';
import {
  change,
  destroy,
  Field,
  Form,
  getFormValues,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { getAddons, getPurchaseItems } from 'store/cashiering/selectors';
import {
  fetchReservation,
  updateReservationPurchases,
} from 'store/reservation/actions';
import {
  getAllAddedPurchaseElements,
  getBreakdown,
  getPreAddedInventoryItems,
  getReservation,
  isReservationDayUse,
} from 'store/reservation/selectors';
import { PurchaseItem } from 'types/Api/Availability';
import {
  AllPurchaseItems,
  Breakdown,
  ReservationView,
  UpdatePurchaseFormData,
} from 'types/Api/Reservation';
import { ApiError } from 'types/Api/Shared';
import Store from 'types/Store';
import { Configurator, Router } from 'utils';
import { Path } from 'utils/Router';

import { ReservationInventoryItemDto } from '@ac/library-api';
import { isDefined } from '@ac/library-utils/dist/utils';

import { getErrors } from '@LEGACY/store/selectors';
import { Grid } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import AddonsMenuElement from '../AddonsMenuElement';
import { updateFormValuesByTransactionId } from '../store/actions';
import {
  getConfirmedNewPurchaseOrders,
  getOnlyPreAdded,
  getPurchaseElementsFromFirstNightRate,
  getPurchaseElementsFromLastNightRate,
} from '../store/selectors';
import { FormValues } from '../types';

import styles from './AddonsMenu.style';
import {
  getBreakdownDataPerPerson,
  getBreakdownDataPerReservation,
  mergeDataWithPreAddedItems,
  prepareDataForNewAddedItems,
} from './purchaseElementsUtils';

const NUMBER_TO_ADD = 1;
const NUMBER_TO_REMOVE = -1;

interface PassedProps {
  showAlreadyAdded?: boolean;
  addons: PurchaseItem[];
  purchaseItems: PurchaseItem[];
}

interface AddonsMenuProps
  extends PassedProps,
    WithTranslation,
    InjectedFormProps,
    RouteComponentProps,
    WithStyles<typeof styles> {
  allPreAddedPurchases: AllPurchaseItems;
  confirmedNewPurchaseOrders: PurchaseItem[];
  onChange: (form: string, field: string, value: any) => void;
  onDestroy: (form: string) => void;
  orderedItems?: FormValues;
  breakdown: Breakdown;
  reservation: ReservationView;
  updateFormValuesByTransactionId: typeof updateFormValuesByTransactionId;
  fetchReservation: typeof fetchReservation;
  updateReservationPurchases: typeof updateReservationPurchases;
  getOnlyPreAdded: typeof getOnlyPreAdded;
  isPreAddedElement: boolean;
  onlyPreAdded: PurchaseItem[];
  purchaseElementsFromFirstNightRate: PurchaseItem[];
  purchaseElementsFromLastNightRate: PurchaseItem[];
  preAddedInventoryItems: ReservationInventoryItemDto[];
  isReservationDayUse: boolean;
  errors: Array<Error | ApiError>;
}

interface AddonsMenuState {
  isLoading: boolean;
  isPreAddedElement: boolean;
  showAlreadyAdded: boolean;
  previousFormValue: FormValues;
}

class AddonsMenu extends PureComponent<AddonsMenuProps, AddonsMenuState> {
  public state: AddonsMenuState = {
    isLoading: true,
    isPreAddedElement: false,
    showAlreadyAdded: false,
    previousFormValue: {},
  };

  public async componentDidMount() {
    const {
      reservation: { id },
      fetchReservation,
      updateFormValuesByTransactionId,
      orderedItems,
    } = this.props;

    if (orderedItems) this.setState({ previousFormValue: orderedItems });
    await fetchReservation(id);
    await updateFormValuesByTransactionId(ids.ADDONS_FORM, id);
  }

  public render() {
    const { handleSubmit, classes } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.onSubmit)}>
        <Grid container className={classes.addonsWrapper} spacing={2}>
          {this.renderItems()}
        </Grid>
      </Form>
    );
  }

  public onItemClick = (
    itemName: string,
    shouldRemove: boolean,
    numberToAdd: number = NUMBER_TO_ADD,
    numberToRemove: number = NUMBER_TO_REMOVE
  ) => {
    const { onChange, orderedItems } = this.props;
    const isFieldRegistered = Boolean(orderedItems && orderedItems[itemName]);
    const attribute = shouldRemove ? numberToRemove : numberToAdd;
    const value = isFieldRegistered
      ? (orderedItems as FormValues)[itemName].quantity + attribute
      : attribute;
    onChange(ids.ADDONS_FORM, itemName, {
      transactionId: isFieldRegistered
        ? (orderedItems as FormValues)[itemName].transactionId
        : undefined,
      quantity: value,
    });
  };

  public onItemAdd = (itemName: string, numberToAdd?: number) =>
    this.onItemClick(itemName, false, numberToAdd);

  public onItemRemove = (itemName: string, numberToRemove?: number) =>
    this.onItemClick(itemName, true, undefined, numberToRemove);

  public onDeleteClick = (id: string) => {
    const { onChange, orderedItems } = this.props;

    const isFieldRegistered = Boolean(orderedItems && orderedItems[id]);
    onChange(ids.ADDONS_FORM, id, {
      transactionId: isFieldRegistered
        ? (orderedItems as FormValues)[id].transactionId
        : undefined,
      quantity: 0,
    });
  };

  private getElements = () => {
    const {
      onlyPreAdded,
      purchaseElementsFromFirstNightRate,
      purchaseElementsFromLastNightRate,
      addons,
      showAlreadyAdded,
    } = this.props;

    const checkInElements = [
      ...purchaseElementsFromFirstNightRate,
      ...onlyPreAdded,
    ];
    const checkOutElements = [
      ...purchaseElementsFromLastNightRate,
      ...onlyPreAdded,
    ];
    const isCheckInPath = Router.currentPath === Path.checkIn;
    const alreadyAddedElements = isCheckInPath
      ? checkInElements
      : checkOutElements;

    return showAlreadyAdded ? alreadyAddedElements : addons;
  };

  private getOrderedInventoryItemsIds = (): string[] => {
    const { orderedItems = {} } = this.props;
    const purchaseElementsDictionary = Configurator.getPurchaseElements();

    const orderedPurchaseElementsIds = Object.entries(orderedItems)
      .map(([id, value]) => {
        if (value.quantity) {
          return id;
        }
      })
      .filter(isDefined);

    return purchaseElementsDictionary
      .filter(
        (item) =>
          orderedPurchaseElementsIds.includes(item.id) && item.inventoryItemId
      )
      .map((item) => item.inventoryItemId)
      .filter(isDefined);
  };

  private isElementOrdered = (element: PurchaseItem): boolean => {
    const { orderedItems = {} } = this.props;

    return !!orderedItems[element.id]?.quantity;
  };

  private shouldElementBeRendered = (element: PurchaseItem): boolean => {
    const { preAddedInventoryItems, showAlreadyAdded } = this.props;

    if (
      showAlreadyAdded ||
      !element.inventoryItemId ||
      this.isElementOrdered(element)
    ) {
      return true;
    }

    const preAddedInventoryItemsIds = preAddedInventoryItems.map(
      (item) => item.inventoryItemId
    );
    const orderedInventoryItemsIds = this.getOrderedInventoryItemsIds();
    const allIncludedInventoryItemsIds = [
      ...preAddedInventoryItemsIds,
      ...orderedInventoryItemsIds,
    ];

    const isAlreadyAddedAsInventoryItemAndIsPerStay = (
      purchaseItem: PurchaseItem
    ) => {
      return (
        purchaseItem.inventoryItemId &&
        purchaseItem.isPerPerson &&
        allIncludedInventoryItemsIds.includes(purchaseItem.inventoryItemId)
      );
    };
    if (isAlreadyAddedAsInventoryItemAndIsPerStay(element)) {
      return false;
    }

    return true;
  };

  private renderItems = () => {
    const {
      orderedItems,
      allPreAddedPurchases,
      showAlreadyAdded,
      onlyPreAdded,
    } = this.props;
    const elements = this.getElements(); // TODO: Test

    return elements.map((elem, index) => {
      const { id, code, fromRate: isElementFromRate } = elem;
      const backgroundUrl = Configurator.getPurchaseElementImage(code)?.content;

      const elemPreAddedInfo = onlyPreAdded.find(
        (preAdded) => preAdded.id === elem.id
      );
      elem.totalRemaining = elemPreAddedInfo
        ? elemPreAddedInfo.totalRemaining
        : elem.totalRemaining;

      return this.shouldElementBeRendered(elem) ? (
        <Grid item xs={6} md={4} lg={3} xl={2} key={`${id}- ${index}`}>
          <Field
            name={id}
            data={elem}
            component={AddonsMenuElement}
            onItemAdd={this.onItemAdd}
            backgroundUrl={showAlreadyAdded ? null : backgroundUrl}
            onItemRemove={this.onItemRemove}
            onItemDelete={this.onDeleteClick}
            isPreAddedElement={showAlreadyAdded || false}
            quantity={orderedItems && orderedItems[id]?.quantity}
            isElementFromRate={showAlreadyAdded ? isElementFromRate : false}
            allPreAddedPurchases={showAlreadyAdded && allPreAddedPurchases}
          />
        </Grid>
      ) : null;
    });
  };

  private onSubmit = async (formValues: FormValues) => {
    await this.updatePurchases(formValues);
    if (!this.props.errors?.length) {
      this.changeRoute(Router.nextStepURL);
    }
  };

  private updatePurchases = async (formValues: FormValues) => {
    const { reservation } = this.props;
    const { updateReservationPurchases } = this.props;
    const { previousFormValue } = this.state;

    return updateReservationPurchases(
      reservation,
      formValues,
      previousFormValue
    );
  };

  private changeRoute = (route: string) => {
    const { history } = this.props;
    history.push(route);
  };
}

const mapStateToProps = (state: Store, props: AddonsMenuProps) => ({
  errors: getErrors(state),
  confirmedNewPurchaseOrders: getConfirmedNewPurchaseOrders(state),
  orderedItems: getFormValues(ids.ADDONS_FORM)(state),
  breakdown: getBreakdown(state),
  reservation: getReservation(state),
  allPreAddedPurchases: getAllAddedPurchaseElements(state),
  onlyPreAdded: getOnlyPreAdded(state),
  purchaseItems: getPurchaseItems(state),
  addons: getAddons(state, props),
  purchaseElementsFromFirstNightRate:
    getPurchaseElementsFromFirstNightRate(state),
  purchaseElementsFromLastNightRate:
    getPurchaseElementsFromLastNightRate(state),
  isReservationDayUse: isReservationDayUse(state),
  preAddedInventoryItems: getPreAddedInventoryItems(state),
});

const mapDispatchToProps = {
  updateReservationPurchases,
  updateFormValuesByTransactionId,
  fetchReservation,
  onDestroy: destroy,
  onChange: change,
};

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  reduxForm({
    form: ids.ADDONS_FORM,
    destroyOnUnmount: false,
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(AddonsMenu) as (props: PassedProps) => JSX.Element;
